import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Header = ({ siteTitle }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const toggleBurger = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <header>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <Link prefetch to="/" className="navbar-item">
            <span className="title is-6">Pogoda podróże</span>
          </Link>

          <a onClick={(e) => { toggleBurger(e); e.preventDefault(); return false; }} href="/" role="button" className={`navbar-burger ${showMobileMenu ? 'is-active' : ''}`} aria-label="menu" aria-expanded="false" data-target="main-navbar">
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>

        <div id="main-navbar" className={`navbar-menu ${showMobileMenu ? 'is-active' : ''}`}>

          <div className="navbar-start">

            <Link onClick={toggleBurger} prefetch to="/" className="navbar-item">
              Kraje
            </Link>

          </div>

        </div>
      </nav>

    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: 'Pogoda na świecie',
};

export default Header;
