/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';

const formatDate = (date, lang) => {
  let str = '';
  try {
    str = new Intl.DateTimeFormat(lang, {
      year: 'numeric', month: 'long', day: 'numeric', weekday: 'long', hour: 'numeric', minute: 'numeric',
    }).format(new Date());
  } catch (e) {
    str = ` ${new Date(date).toString()}`;
  }
  str = ` ${str || new Date(date).toString()}`;
  return str;
};

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    },
    siteBuildMetadata {
     buildTime(fromNow: false)
    }
  }
  `);

  const { buildTime } = data.siteBuildMetadata;
  const formattedDate = formatDate(buildTime, 'pl');

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || 'Title'} />
      <div>
        <main>{children}</main>
        <footer className="footer has-text-centered">
          Pogoda Podróże ©
          {' '}
          {new Date().getFullYear()}
          {' '}
          <small>
            { formattedDate }
          </small>
        </footer>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
